import React, { useEffect, useState } from 'react';
import './ReservationConfirmation.css';
import check from '../../../assets/booking/check.svg';
import info from '../../../assets/info.png';
import plug from '../../../assets/room/widget/plug.svg'
import water from '../../../assets/room/widget/water.svg'
import insurance from '../../../assets/room/widget/insurance.svg'
import building from '../../../assets/room/icons/payment.png'
import wifi from '../../../assets/room/widget/wifi.svg'
import heater from '../../../assets/heater 1.png'
import cable from '../../../assets/room/widget/cable-tv.svg'
import wipe from '../../../assets/room/widget/wipe.svg'
import moment from 'moment';
import { jsPDF } from "jspdf";
import { API_KEY, URL_Back } from '../../Variables';
import { useTranslation } from 'react-i18next';

const ReservationConfirmation = () => {
  const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);
  const [clientLastName, setClientLastName] = useState('');
  const [lotId, setLotId] = useState('');
  const [lotData, setLotData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const { t } = useTranslation();



  useEffect(() => {
    const storedArrivalDate = localStorage.getItem('arrivalDate');
    const storedDepartureDate = localStorage.getItem('departureDate');
    const formData = JSON.parse(localStorage.getItem('formData')) || {};

    if (storedArrivalDate && storedDepartureDate) {
      setArrivalDate(moment(storedArrivalDate));
      setDepartureDate(moment(storedDepartureDate));
    }

    if (formData.lastName) {
      setClientLastName(formData.lastName);
    }

    if (formData.lotId) {
      setLotId(formData.lotId);
    }
    if (formData.paymentMethod) {
      setPaymentMethod(formData.paymentMethod);
    }
  }, []);

  const formatDate = (date) => {
    return date ? date.format('ddd DD MMM YYYY') : '';
  };
  const calculateNights = (arrivalDate, departureDate) => {
    if (arrivalDate && departureDate) {
      return departureDate.diff(arrivalDate, 'days');
    }
    return 0;
  };
  const handleDownload = () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Confirmation de Réservation", 20, 20);

    doc.setFontSize(12);
    doc.text(`Merci, ${clientLastName}!`, 20, 30);
    doc.text("Votre réservation a été confirmée avec succès.", 20, 40);

    doc.text("Détails de votre réservation :", 20, 60);
    doc.text(`Arrivée : ${formatDate(arrivalDate)}`, 20, 70);
    doc.text(`Départ : ${formatDate(departureDate)}`, 20, 80);
    doc.text(`Durée totale du séjour : ${calculateNights(arrivalDate, departureDate)} nuits`, 20, 90);

    doc.text("Services inclus :", 20, 110);
    const services = [
      ...(lotData?.wi_fi !== 0 ? ["WiFi"] : []),
      ...(lotData?.water !== 0 ? ["Eau"] : []),
      ...(lotData?.electricity !== 0 ? ["Électricité"] : []),
      ...(lotData?.home_insurance !== 0 ? ["Assurance habitation"] : []),
      ...(lotData?.heating !== 0 ? ["Chauffage"] : []),
      ...(lotData?.cable_tv !== 0 ? ["Télévision par câble"] : []),
      ...(lotData?.cleaning !== 0 ? ["Nettoyage"] : []),
      ...(lotData?.building_service_charge !== 0 ? ["Charges de copropriété"] : [])
    ];

    services.forEach((service, index) => {
      doc.text(`- ${service}`, 25, 120 + index * 10);
    });

    doc.text("Montant total : € 416,82", 20, 220);
    doc.text("Méthode de paiement : Virement bancaire", 20, 230);

    doc.save("confirmation_reservation.pdf");
  };
  useEffect(() => {
    if (!lotId) {
      console.warn("lotId n'est pas défini, le fetch est ignoré.");
      return;
    }

    const headers = {
      'apiKey': `${API_KEY}`,
    };

    fetch(`${URL_Back}/lot/${lotId}`, { method: 'GET', mode: 'cors', headers })
      .then(response => response.json())
      .then(data => {
        if (data?.data?.lot) {
          setLotData(data.data.lot);
        } else {
          console.warn("Aucune donnée de lot trouvée :", data);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données :', error);
      });
  }, [lotId, API_KEY]);


  return (
    <div className="reservation-confirmation container mb-5">
      <div className="confirmation-header">
        <img src={check} alt="check" />
        <div>
          <h1>{t("bookingapart.confirm1")}</h1>
          {paymentMethod === "virement" ? (
           <p>
           {t("bookingapart.confirm2")}, {clientLastName} ! Votre réservation est confirmée sous condition. Veuillez effectuer un virement bancaire de [montant] € dans les plus brefs délais. 
           Si le paiement n'est pas reçu dans les délais impartis, votre réservation sera automatiquement annulée.
         </p>
         
          ) : (
            <p>
              {t("bookingapart.confirm2")}, {clientLastName} ! {t("bookingapart.confirm3")} [montant] € {t("bookingapart.confirm4")}. <br />
              {t("bookingapart.confirm5")}
            </p>
          )}

        </div>
      </div>

      <div className="reservation-booking-details">
        <div className="reservation-details-confirm">
          <div className="stay-details-confirm">
            <h2>{t("bookingapart.sejour")}</h2>
            <p>Bâtiment 1 Test - lot 1 test</p>
            <ul className="features-list">
              {lotData?.wi_fi !== 0 && (<li><img src={wifi} className="icon" alt="wifi" /> {t("apartment.wifi")}</li>)}
              {lotData?.water !== 0 && lotData?.water !== undefined && (<li><img src={water} className="icon" alt="Water" /> {t("apartment.water")}</li>)}
              {lotData?.electricity && lotData.electricity !== 0 && (<li><img src={plug} className="icon" alt="plug" /> {t("apartment.elect")}</li>)}
              {lotData?.home_insurance !== 0 && (<li><img src={insurance} className="icon" alt="insurance" /> {t("apartment.insurance")}</li>)}
              {lotData?.heating !== 0 && (<li><img src={heater} className="icon" alt="heater" /> {t("apartment.heat")}</li>)}
              {lotData?.cable_tv !== 0 && (<li><img src={cable} className="icon" alt="cable tv" /> {t("apartment.cable")}</li>)}
              {lotData?.cleaning !== 0 && (<li><img src={wipe} className="icon" alt="wipe" />{t("apartment.clean")}</li>)}
              {lotData?.building_service_charge !== 0 && (<li><img src={building} className="icon" alt="building" /> {t("apartment.serviceCharge")}</li>)}
            </ul>

          </div>

          <div className="booking-details-confirm">
            <h4>{t("bookingapart.detail")}</h4>
            <div class="date-container-confirm">
              <p class="date-item">{t("bookingapart.arrivee")}<br /> <strong>{formatDate(arrivalDate)}</strong></p>
              <div class="date-separator-confirm"></div>
              <p class="date-item">{t("bookingapart.depart")} <br /> <strong>{formatDate(departureDate)}</strong></p>
            </div>

            <p>{t("bookingapart.duree")} : <strong>{calculateNights(arrivalDate, departureDate)} nuits</strong></p>
          </div>
        </div>

        <div className="payment-summary">
          <div className="amount">
            <p>{t("bookingapart.montant")}</p>
            <span>€ 416,82</span>
          </div>
          <div className="payment-method">
            <p>{t("bookingapart.paymentmethod")}</p>
            <span>Virement bancaire</span>
          </div>
        </div>
        <div className='button-res-container'>
          <button className="download-button" onClick={handleDownload}>{t("bookingapart.download")}</button>
        </div>
        <footer className="support-info">
          <p>
            {t("bookingapart.question")} <br />
            {t("bookingapart.question2")} [numéro de téléphone] {t("bookingapart.question3")} [e-mail de support].
          </p>
        </footer>
      </div>


    </div>
  );
};

export default ReservationConfirmation;
