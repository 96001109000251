import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./SpecialDemandes.css";
import virment from "../../../assets/booking/virement.svg";
import carte from "../../../assets/booking/carte.svg";
import axios from "axios";
import { API_KEY, URL_Back } from "../../Variables";
import { useTranslation } from "react-i18next";

const SpecialDemandes = ({ lotData }) => {
  const [selectedOption, setSelectedOption] = useState("carte");
  const { t, i18n } = useTranslation();

  const handleOptionChange = (option) => {
    setSelectedOption(option);

    const savedFormData = JSON.parse(localStorage.getItem("formData")) || {};
    savedFormData.paymentMethod = option;

    localStorage.setItem("formData", JSON.stringify(savedFormData));
  };

  useEffect(() => {
    const savedFormData = localStorage.getItem("formData");
    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      setSelectedOption(parsedFormData?.paymentMethod || "carte");
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState('');

  const handlePayment = async (method) => {
    const formData = JSON.parse(localStorage.getItem("formData")) || {};

    const requestData = {
      lot_id: formData.lotId,
      start_date: formData.arrivalDate,
      end_date: formData.departureDate,
      name: formData.lastName,
      fname: formData.firstName,
      email: formData.email,
      country: formData.country,
      phone: formData.phoneNumber,
      phone_country_name: formData.countryCode,
      specifique_demande: formData.specialRequests,
      start_hour: formData.arrivalTime,
      payment_method: method,
      accept: "on",
    };

    try {
      const response = await axios.post(`${URL_Back}/rental/store`, requestData, {
        headers: {
          Accept: 'application/json',
          apiKey: `${API_KEY}`,
          'Content-Type': 'multipart/form-data',
          'lang': `${i18n.language}`
        },
      });

      if (response.status === 200) {
        console.log('Payment request successful:', response.data);

        if (method === 1) {
          if (response.data.stripe) {
            window.open(response.data.stripe, '_blank');
          } else {
            console.error("Stripe URL manquante dans la réponse.");
          }
        } else {
          window.open('/confirm', '_blank');
        }
      } else {
        console.error('Error with the payment request:', response.status, response.data);
      }
    } catch (error) {
      console.log('Erreur capturée:', error);
      if (error.response) {
        console.log('Réponse du serveur:', error.response);
        if (error.response.status === 422 && error.response.data && error.response.data.data && error.response.data.data.message) {
          setErrorMessage(error.response.data.data.message);
          toast.error(error.response.data.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 6000,
          });

        } else {
          console.error('Error response from the server:', error.response.status, error.response.data);
        }
      } else if (error.request) {
        console.error('No response received from the server:', error.request);
      } else {
        console.error('Error setting up the request:', error.message);
      }
    }
  };



  return (

    <div className="special-demandes">
      <h3>{t("bookingapart.demande")}</h3>
      <div className="btn-cartes">
        <div className="options">
          <div className="option-container">
            <div
              className={`option ${selectedOption === "carte" ? "active" : ""}`}
              onClick={() => handleOptionChange("carte")}
            >
              <span className="tick">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M1.871 8.22052C1.51672 8.22154 1.16996 8.32543 0.870843 8.52018C0.571724 8.71493 0.332466 8.99257 0.180749 9.32099C0.0290333 9.64941 -0.0289406 10.0152 0.0135347 10.376C0.0560101 10.7368 0.197199 11.0779 0.420767 11.3598L5.18671 17.3488C5.35664 17.5653 5.57446 17.737 5.82208 17.8498C6.0697 17.9625 6.33997 18.013 6.61045 17.9971C7.18893 17.9653 7.7112 17.6478 8.04418 17.1259L17.9443 0.770093C17.9459 0.76738 17.9476 0.764667 17.9493 0.761994C18.0422 0.615684 18.0121 0.325737 17.8203 0.143588C17.7677 0.0935679 17.7056 0.0551385 17.6379 0.0306665C17.5702 0.00619455 17.4983 -0.00380191 17.4267 0.00129304C17.3551 0.00638799 17.2853 0.0264665 17.2215 0.0602917C17.1578 0.0941169 17.1014 0.140973 17.056 0.197974C17.0525 0.202455 17.0488 0.206869 17.045 0.211216L7.06067 11.7834C7.02268 11.8274 6.97654 11.8632 6.92493 11.8888C6.87331 11.9144 6.81726 11.9292 6.76002 11.9324C6.70278 11.9356 6.64549 11.9271 6.59149 11.9073C6.53748 11.8876 6.48784 11.8571 6.44544 11.8175L3.13182 8.72421C2.78767 8.40058 2.33786 8.22089 1.871 8.22052Z"
                    fill="white"
                  />
                </svg>
              </span>
              <img src={carte} alt="carte" />

            </div>
            <label htmlFor="carte">{t("bookingapart.carte")}</label>
          </div>

          <div className="option-container">
            <div
              className={`option ${selectedOption === "virement" ? "active" : ""}`}
              onClick={() => handleOptionChange("virement")}
            >
              <span className="tick">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M1.871 8.22052C1.51672 8.22154 1.16996 8.32543 0.870843 8.52018C0.571724 8.71493 0.332466 8.99257 0.180749 9.32099C0.0290333 9.64941 -0.0289406 10.0152 0.0135347 10.376C0.0560101 10.7368 0.197199 11.0779 0.420767 11.3598L5.18671 17.3488C5.35664 17.5653 5.57446 17.737 5.82208 17.8498C6.0697 17.9625 6.33997 18.013 6.61045 17.9971C7.18893 17.9653 7.7112 17.6478 8.04418 17.1259L17.9443 0.770093C17.9459 0.76738 17.9476 0.764667 17.9493 0.761994C18.0422 0.615684 18.0121 0.325737 17.8203 0.143588C17.7677 0.0935679 17.7056 0.0551385 17.6379 0.0306665C17.5702 0.00619455 17.4983 -0.00380191 17.4267 0.00129304C17.3551 0.00638799 17.2853 0.0264665 17.2215 0.0602917C17.1578 0.0941169 17.1014 0.140973 17.056 0.197974C17.0525 0.202455 17.0488 0.206869 17.045 0.211216L7.06067 11.7834C7.02268 11.8274 6.97654 11.8632 6.92493 11.8888C6.87331 11.9144 6.81726 11.9292 6.76002 11.9324C6.70278 11.9356 6.64549 11.9271 6.59149 11.9073C6.53748 11.8876 6.48784 11.8571 6.44544 11.8175L3.13182 8.72421C2.78767 8.40058 2.33786 8.22089 1.871 8.22052Z"
                    fill="white"
                  />
                </svg>
              </span>
              <img src={virment} alt="virement" />

            </div>
            <label htmlFor="virement">{t("bookingapart.virement")}</label>
          </div>
        </div>
        {selectedOption === "carte" && (
          <div className="payment-button-container">
            <button className="payment-button" onClick={() => handlePayment(1)}>{t("bookingapart.payment")}</button>
          </div>
        )}
      </div>

      {selectedOption === "virement" && (
        <div className="virement-info">
          <h4>{t("bookingapart.msgvir")}</h4>
          <table className="virement-table">
            <tbody>
              <tr>
                <td>{t("bookingapart.msgvir2")}</td>
                <td>€ {lotData?.loyer_hc + lotData?.charges}</td>
              </tr>
              <tr>
                <td>{t("bookingapart.msgvir3")}</td>
                <td>Landes location</td>
              </tr>
              <tr>
                <td>{t("bookingapart.msgvir4")}</td>
                <td>
                  <p><strong>{t("bookingapart.msgvir5")}</strong> {t("bookingapart.msgvir6")}</p>
                  <p><strong>{t("bookingapart.numbank")} :</strong> 30003 02125 45454 22222</p>
                  <p><strong>IBAN :</strong> FR12 3265 4578 5623 1524 8998</p>
                  <p><strong>BIC :</strong> SOGRETHD</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="payment-button-container">
            <button className="payment-button" onClick={() => handlePayment(2)} >{t("bookingapart.reserver")}</button>
          </div>
        </div>


      )}
      <ToastContainer />
    </div>
  );
};

export default SpecialDemandes;
