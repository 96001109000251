import React from 'react'
import './CancelReservation.css'
import cancel from '../../../assets/booking/cancel.svg'
import { useTranslation } from 'react-i18next';

const CancelReservation=()=>{
    const { t, i18n } = useTranslation();
    const language = i18n.language;

  return (
    <div className="cancel-reservation-container">
      <div className="icon-container">
        <span className="calendar"><img src={cancel}  alt="cancel" /></span>
      </div>
      <h2 className="cancel-message">{t("bookingapart.cancelMessage")}</h2>
      <button className="retry-button"  onClick={() => {window.location.href = `/search-cities`;}}>
      {t("bookingapart.cancelButton")}
      </button>
    </div>
  );
}

export default CancelReservation