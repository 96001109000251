import React, { useState, useEffect, memo } from 'react';
import './InformationsReservation.css';
import connect from '../../../assets/booking/connecter.svg';
import require from '../../../assets/booking/required.svg';
import Select from 'react-select';
import { getNames } from 'country-list';
import countriesData from 'i18n-iso-countries';
import TimePicker from 'react-time-picker';
import { useTranslation } from 'react-i18next';

const InformationsReservation = (({errors}) => {
    const {t,i18n} =useTranslation();

const savedFormData = JSON.parse(localStorage.getItem('formData')) || {
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        phoneNumber: '',
        arrivalTime: '',
        confirmDigital: false,
        specialRequests: '',
    };

    const [formData, setFormData] = useState(savedFormData);
   


    const updateCountriesLocale = (language) => {
        const localePath = require(`i18n-iso-countries/langs/${language}.json`);
        countriesData.registerLocale(localePath);
      };
      updateCountriesLocale(i18n.language);
      const countryNames = countriesData.getNames(i18n.language);
      
      const countries = Object.keys(countryNames).map((key) => {
          const name = countryNames[key];
          const code = countriesData.getAlpha2Code(name, i18n.language);
          return {
              label: name,
              value: name,
              code,
          };
      });

    const handleChange = (e) => {

        const { name, value, type, checked } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData };

            if (type === 'checkbox') {
                updatedData[name] = checked;
            } else {
                updatedData[name] = value;
            }

            localStorage.setItem('formData', JSON.stringify(updatedData));

            return updatedData;
        });
    };

    const handleSelectChange = (selectedOption) => {
        const { value, code } = selectedOption;
        setFormData((prevData) => {
            const updatedData = { ...prevData, country: value, countryCode: code };
            localStorage.setItem('formData', JSON.stringify(updatedData));
            return updatedData;
        });
    };
    
    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('formData'));
        if (savedData) {
            const updatedData = { ...savedData };
            if (!savedData.countryCode && savedData.country) {
                const countryObj = countries.find((c) => c.label === savedData.country);
                if (countryObj) {
                    updatedData.countryCode = countryObj.code;
                }
            }
            setFormData(updatedData);
            localStorage.setItem('formData', JSON.stringify(updatedData));
        };
    }, []);
    
    const handleTimeChange = (time) => {
        setFormData((prevData) => {
            const updatedData = { ...prevData, arrivalTime: time };
            localStorage.setItem('formData', JSON.stringify(updatedData));
            return updatedData;
        });
    };

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('formData'));
        if (savedData) {
            setFormData(savedData);
        }
    }, []);
    

    return (
        <div className="user-details-form">
            <div className="login-reminder">
                <p>
                    <img src={connect} alt="Connectez-vous" />
                    <span>
                        <a>{t("bookingapart.connect1")}</a> {t("bookingapart.connect2")} <a>{t("bookingapart.connect3")}</a> {t("bookingapart.connect4")}
                    </span>
                </p>
            </div>

            {/* Coordonnées */}
            <div className="form-section">
                <h3>{t("bookingapart.saisir")}</h3>
                <p>
                    <img src={require} alt="required" />
                    <span>
                    {t("bookingapart.complete")}{' '}
                        <span className="required">*</span>.
                    </span>
                </p>
                <form className="form-grid">
                    <div className="form-group">
                        <label>
                        {t("bookingapart.lastName")}<span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Prénom"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                    {errors.firstName && <span className="error">{errors.firstName}</span>}
                    </div>
                    <div className="form-group">
                        <label>
                        {t("bookingapart.firstName")}<span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            placeholder="Nom"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                        />
                        {errors.lastName && <span className="error">{errors.lastName}</span>}
                    </div>
                    <div className="form-group email-group">
                        <label>
                        {t("bookingapart.email")}<span className="required">*</span>
                        </label>
                        <input
                            type="email"
                            placeholder="Adresse e-mail"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <label>
                        {t("bookingapart.Pays")}<span className="required">*</span>
                        </label>
                        <Select
                            value={countries.find(
                                (c) => c.label === formData.country 
                            )}
                            options={countries}
                            onChange={handleSelectChange}
                            placeholder="Select a country"
                        />
                      {errors.country && <span className="error">{errors.country}</span>}
                    </div>
                    <div className="form-group">
                        <label>
                        {t("bookingapart.phone")}<span className="required">*</span>
                        </label>
                        <input
                            type="tel"
                            placeholder="Numéro de téléphone"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            required
                        />
                    {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                    </div>
                    <div className="form-group check-group">
                        <label>
                            <input
                                type="checkbox"
                                className="custom-checkbox"
                                name="confirmDigital"
                                checked={formData.confirmDigital}
                                onChange={handleChange}
                            />
                            {t("bookingapart.digital")}
                        </label>
                    </div>
                </form>
            </div>

            {/* Special Requests */}
            <div className="special-section">
                <h3>{t("bookingapart.special")}</h3>
                <p>
                {t("bookingapart.special2")}
                </p>
                <h4> {t("bookingapart.special3")}</h4>
                <div className="form-group">
                    <textarea
                        placeholder= {t("bookingapart.special4")}
                        name="specialRequests"
                        value={formData.specialRequests}
                        onChange={handleChange}
                    ></textarea>
                </div>
            </div>

            {/* Arrival Time */}
            <div className="arrival-time">
                <h3>{t("bookingapart.heure")}</h3>
                <p>{t("bookingapart.msg")}</p>
                <div className="form-group">
                    <label>{t("bookingapart.msg2")}</label>
                    <TimePicker
                        id="timePicker"
                        onChange={handleTimeChange}
                        value={formData.arrivalTime}
                        disableClock={true}
                        clearIcon={null}
                        className="custom-time-picker"
                    />
                </div>
            </div>

            {/* À savoir */}
            <div className="form-section">
                <h3>{t("bookingapart.savoir")} :</h3>
                <ul>
                    <li>{t("bookingapart.msg3")}</li>
                </ul>
            </div>
        </div>
    );
});

export default InformationsReservation;
