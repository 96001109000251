import React, { useState, useRef } from 'react';
import "./Intro.css";
import { useNavigate } from 'react-router-dom';
import SelectCity from '../../SearchCities/Search/SelectCity/SelectCity';
import calendarIcon from '../../../assets/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from 'react-i18next';

const Intro = ({ backgroundImage }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [city, setCity] = useState('');
  const [date, setDate] = useState([]);
  const [travelers, setTravelers] = useState();
  const datePickerRef = useRef(null); 
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); 

  const handleMoveInDateChange = (date) => {
    setDate(date || []);
  };

  const handleClick = () => {
    const [arrivalDate,departureDate] = date;
    const formattedDepartureDate = departureDate ? departureDate.format('YYYY-MM-DD') : '';
    const formattedArrivalDate = arrivalDate ? arrivalDate.format('YYYY-MM-DD') : '';
    
    localStorage.setItem('departureDate', formattedDepartureDate);
    localStorage.setItem('arrivalDate', formattedArrivalDate);
    
    const searchParams = new URLSearchParams({ city });
    if (formattedDepartureDate) {
      searchParams.append('start_date', formattedDepartureDate);
    }
    if (formattedArrivalDate) {
      searchParams.append('end_date', formattedArrivalDate);
    }
    if (travelers) {
      searchParams.append('nbr_pers', travelers);
    }
    const url = `/${language}/search-cities?${searchParams.toString()}`;
    navigate(url);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const handleInputClick = () => {
    if (isCalendarOpen) {
      datePickerRef.current.closeCalendar(); 
    } else {
      datePickerRef.current.openCalendar(); 
    }
    setIsCalendarOpen(!isCalendarOpen);
  };

  return (
    <div className='Intro-container d-block py-md-5 py-4 container-fluid mb-md-5 mb-4' style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className='Content-container'>
        <h2>{t('home.slogan_banner')}</h2>
        <div className='Input-container d-flex justify-content-center gap-2 w-100'>
          <div className='input-country'>
            <SelectCity text={t('intro.destination')} onChange={(selectedValue) => setCity(selectedValue.value)} alert={true} />
          </div>
          <div className='input-date'>
            <DatePicker
              ref={datePickerRef} 
              placeholder={t('intro.depart')}
              numberOfMonths={2}
              range 
              calendarPosition="bottom-center"
              mobileLabels={{
                months: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
                days: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
              }}
              value={date}
              name="date"
              dateFormat="dd/MM/yyyy"
              onChange={handleMoveInDateChange}
              render={
                <div className="input-datepicker">
                  <input
                    type="text"
                    className='Select-country-container'
                    value={date.length === 2 ? `${date[0].format('DD/MM/YYYY')} - ${date[1].format('DD/MM/YYYY')}` : ''}
                    readOnly
                    placeholder={t('intro.depart')}
                    onClick={handleInputClick} 
                  />
                  <span className="calendar-icon">
                    <img src={calendarIcon} alt="Calendar" />
                  </span>
                </div>
              }
              minDate={tomorrow}
            />
          </div>
          <div className='input-travelers'>
            <input
              type="number"
              className='Select-country-container '
              value={travelers}
              min={1}
              onChange={(e) => setTravelers(e.target.value)}
              placeholder={t('intro.travelers')}
            />
          </div>
          <button className='Search-btn' onClick={handleClick}>{t('intro.btn_search')}</button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
