import React, { useCallback,useEffect, useState , useRef } from "react";
import "./MultiStepForm.css";
import DetailsReservation from "../DetailsReservation/DetailsReservation";
import InformationsReservation from "../InformationsReservation/InformationsReservation";
import SpecialDemandes from "../SpecialDemandes/SpecialDemandes";
import { useTranslation } from "react-i18next";


const MultiStepForm = ({ lotData }) => {
    const [currentStep, setCurrentStep] = useState(2);
    const {t}=useTranslation();

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        country: "",
    });
    
    const [errors, setErrors] = useState({});

    const loadFormData = () => {
        const storedFormData = localStorage.getItem('formData');
        if (storedFormData) {
            setFormData(JSON.parse(storedFormData));
        }
    };

    useEffect(() => {
        loadFormData();
        const handleStorageChange = () => {
            loadFormData();
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); 


    const validateForm = (data) => {
        const newErrors = {};
    
        if (!data.firstName || data.firstName.trim() === "") {
            newErrors.firstName = t("bookingapart.firstNameRequired");
        }
    
        if (!data.lastName || data.lastName.trim() === "") {
            newErrors.lastName = t("bookingapart.lastNameRequired");
        }
    
        if (!data.email) {
            newErrors.email = t("bookingapart.emailRequired");
        }
    
        if (!data.phoneNumber) {
            newErrors.phoneNumber = t("bookingapart.phoneRequired");
        }
    
        if (!data.country) {
            newErrors.country = t("bookingapart.countryRequired");
        }
    
        return newErrors; 
    };
    

    const handleNextStep = () => {
   
        const storedFormData = JSON.parse(localStorage.getItem('formData'));
        const validationErrors = validateForm(storedFormData);
    
        setErrors(validationErrors); 
    
        if (Object.keys(validationErrors).length === 0) {
            if (currentStep < 3) {
                setCurrentStep((prevStep) => prevStep + 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        } else {
            console.log("Validation errors exist. Staying on the current step.");
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    
    const handlePreviousStep = () => {
        if (currentStep > 1) {
            setErrors({}); 
            setCurrentStep((prevStep) => prevStep - 1);
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };
    

  
    const handleDataChange = (step, data) => {
        setFormData((prevData) => ({
            ...prevData,
            [step]: data,
        }));
    };
    const Step1 = () => (
        <div>
            <DetailsReservation lotData={lotData} />
        </div>
    );

    const Step2 = () => (
        <div>
           <InformationsReservation errors={errors} setErrors={setErrors} validateForm={validateForm}/>
        </div>
    );

    const Step3 = () => (
        <div>
            <SpecialDemandes lotData={lotData} />
        </div>
    );

    return (
        <div className="multi-step-form mb-5">
            {/* Barre de progression */}
            <div className="steps-container">
                {/** Étape 1 */}
                <div className={`step stepOne step-with-svg`}>
                    <div
                        className={`step-circle ${currentStep >= 1 ? "completed" : ""}`}
                    >
                        {currentStep > 1 ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="14"
                                viewBox="0 0 19 14"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.651 0.253454C19.0801 0.629745 19.119 1.28529 18.7374 1.70983L7.99446 13.6614C7.61084 14.0881 6.95218 14.1153 6.53492 13.7216L0.318375 7.8557C-0.0693087 7.48987 -0.10642 6.8803 0.2262 6.46358C0.593224 6.00374 1.2767 5.94045 1.7135 6.33423L6.5367 10.6826C6.95586 11.0605 7.60118 11.0267 7.97882 10.6072L17.2193 0.338172C17.5932 -0.0773845 18.2309 -0.115053 18.651 0.253454Z"
                                    fill="white"
                                />
                            </svg>
                        ) : (
                            "1"
                        )}
                    </div>
                    <span className="step-title">{t("bookingapart.etape1")}</span>
                </div>

                {/** Étape 2 */}
                <div
                    className={`step stepTwo ${currentStep > 2 ? "step-with-svg" : ""
                        }`}
                >
                    <div
                        className={`step-circle ${currentStep >= 2 ? "completed" : ""}`}
                    >
                        {currentStep > 2 ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="14"
                                viewBox="0 0 19 14"
                                fill="none"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M18.651 0.253454C19.0801 0.629745 19.119 1.28529 18.7374 1.70983L7.99446 13.6614C7.61084 14.0881 6.95218 14.1153 6.53492 13.7216L0.318375 7.8557C-0.0693087 7.48987 -0.10642 6.8803 0.2262 6.46358C0.593224 6.00374 1.2767 5.94045 1.7135 6.33423L6.5367 10.6826C6.95586 11.0605 7.60118 11.0267 7.97882 10.6072L17.2193 0.338172C17.5932 -0.0773845 18.2309 -0.115053 18.651 0.253454Z"
                                    fill="white"
                                />
                            </svg>
                        ) : (
                            "2"
                        )}
                    </div>
                    <span className="step-title">{t("bookingapart.etape2")}</span>
                </div>

                {/** Étape 3 */}
                <div className={`step stepThree`}>
                    <div
                        className={`step-circle ${currentStep >= 3 ? "completed" : ""}`}
                    >
                        3
                    </div>
                    <span className="step-title">{t("bookingapart.etape3")}</span>
                </div>
            </div>


            {/* Contenu des étapes */}
            <div className="step-content-container">
                <div className="step-1">
                    <Step1 lotData={lotData} />
                </div>
                <div className="step-other">
                {currentStep === 2 && <Step2 errors={errors}/>}

                    {currentStep === 3 && <Step3 lotData={lotData} />}

                    <div className="next-button">
                        {currentStep === 2 && (
                            <button onClick={()=>{handleNextStep({})}} className="btn next">
                                {t("bookingapart.suivant")}
                            </button>
                        )}
                    </div>
           
                    {currentStep === 3 && (
                        <a onClick={handlePreviousStep} className="link-previous">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="13" height="13" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                    <path d="M3569 5107 c-25 -7 -65 -25 -90 -39 -63 -38 -2312 -2292 -2337 -2344 -11 -22 -26 -68 -33 -102 -16 -75 -2 -160 37 -233 17 -31 394 -414 1172 -1191 1290 -1288 1182 -1192 1342 -1192 72 0 94 4 147 28 184 84 262 308 171 491 -13 26 -368 388 -1016 1038 l-997 997 997 998 c648 649 1003 1011 1016 1037 115 233 -39 504 -295 521 -40 2 -87 -2 -114 -9z" />
                                </g>
                            </svg> {t("bookingapart.precedent")}
                        </a>

                    )}
                </div>

            </div>

        </div>


    );
};



export default MultiStepForm;
