import React, { useState , useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './DetailsReservation.css'
import building from '../../../assets/room/icons/payment.png'
import { PiInfo } from "react-icons/pi";
import info from '../../../assets/info.png';
import plug from '../../../assets/room/widget/plug.svg'
import group from '../../../assets/room/icons/Group.svg'
import water from '../../../assets/room/widget/water.svg'
import insurance from '../../../assets/room/widget/insurance.svg'
import wifi from '../../../assets/room/widget/wifi.svg'
import heater from '../../../assets/heater 1.png'
import cable from '../../../assets/room/widget/cable-tv.svg'
import wipe from '../../../assets/room/widget/wipe.svg'
import moment from 'moment'; 

const CustomTooltip = ({ content }) => {
    return <div className="custom-tooltip">{content}</div>;
};
const DetailsReservation = ({ lotData }) => {
    const { i18n, t } = useTranslation();
    const [tooltipActive, setTooltipActive] = useState(false);
    const [tooltipActive1, setTooltipActive1] = useState(false);
    const [arrivalDate, setArrivalDate] = useState(null);
  const [departureDate, setDepartureDate] = useState(null);

  useEffect(() => {
    const storedArrivalDate = localStorage.getItem('arrivalDate');
    const storedDepartureDate = localStorage.getItem('departureDate');
  
    if (storedArrivalDate && storedDepartureDate) {
      const newArrivalDate = moment(storedArrivalDate);
      const newDepartureDate = moment(storedDepartureDate);
  
      if (!newArrivalDate.isSame(arrivalDate) || !newDepartureDate.isSame(departureDate)) {
        setArrivalDate(newArrivalDate);
        setDepartureDate(newDepartureDate);
        const formData = JSON.parse(localStorage.getItem('formData')) || {};
        formData.arrivalDate = newArrivalDate.format('YYYY-MM-DD'); 
        formData.departureDate = newDepartureDate.format('YYYY-MM-DD'); 
        formData.lotId = lotData?.id;  
        localStorage.setItem('formData', JSON.stringify(formData));
      }
    }
  }, []);
  
  const formatDate = (date) => {
    return date ? date.format('ddd DD MMM YYYY') : '';
  };
  const calculateNights = (arrivalDate, departureDate) => {
    if (arrivalDate && departureDate) {
      return departureDate.diff(arrivalDate, 'days'); 
    }
    return 0; 
  };
  
    const handleInfoHover = () => {
        setTooltipActive(true);
    };

    const handleInfoLeave = () => {
        setTooltipActive(false);
    };

    const handleInfoHover1 = () => {
        setTooltipActive1(true);
    };

    const handleInfoLeave1 = () => {
        setTooltipActive1(false);
    };
    console.log('loyer_hc:', lotData?.loyer_hc, 'charges:', lotData?.charges);

    return (
        <div className="reservation-container">
            {/* Séjour chez l’habitant */}
            <div className="stay-details">
                <h3>{t("bookingapart.sejour")}</h3>
                {lotData?.building?.title && lotData?.title && (
                    <h4>{lotData.building.title} - {lotData.title}</h4>
                )}



                <ul className="amenities">
                 
                    {lotData?.max_benefit ? (
                        <div className=" assistance mb-2">
                            {t("apartment.caf")} <PiInfo className="info" />
                            <span className="green"> {t("apartment.up")} -{lotData.max_benefit}€ </span>
                        </div>
                    ) : null}

                    {lotData?.water !== 0 && lotData?.water !== undefined && (
                        <p className="status mb-2">
                            <img src={water} alt="Water" className="icon" />
                            {t("apartment.water")}
                        </p>
                    )}

                    {lotData?.electricity && lotData.electricity !== 0 && (
                        <div>
                            <p className="status mb-2">
                                <img src={plug} className="icon" alt="plug" />
                                <span className="me-3">
                                    {t("apartment.elect")}
                                </span>
                                <img
                                    src={info}
                                    className="img-fluid"
                                    alt="info"
                                    onMouseEnter={handleInfoHover}
                                    onMouseLeave={handleInfoLeave}
                                />
                            </p>
                            {tooltipActive && (
                                <CustomTooltip content={t("apartment.electI")}>
                                </CustomTooltip>
                            )}
                        </div>
                    )}

                    {lotData?.apartment?.options?.some(option => option.title.toLowerCase() === 'gas') && (
                        <div>
                            <p className="status mb-2">
                                <img src={group} className="icon" alt="gas" />
                                <span className="me-3">
                                    {t("apartment.gaz")}
                                </span>
                                <img
                                    src={info}
                                    className="img-fluid"
                                    alt="info"
                                    onMouseEnter={handleInfoHover1}
                                    onMouseLeave={handleInfoLeave1}
                                />
                            </p>
                            {tooltipActive1 && (
                                <CustomTooltip content={t("apartment.electI")} />
                            )}
                        </div>
                    )}
                    {lotData?.home_insurance !== 0 && (
                        <p className="status mb-2">
                            <img src={insurance} className="icon" alt="insurance" />
                            {t("apartment.insurance")}
                        </p>
                    )}

                    {lotData?.wi_fi !== 0 && (
                        <p className="status mb-2">
                            <img src={wifi} className="icon" alt="wifi" />
                            {t("apartment.wifi")}
                        </p>
                    )}

                    {lotData?.cable_tv !== 0 && (
                        <p className="status mb-2">
                            <img src={cable} className="icon" alt="cable tv" />
                            {t("apartment.cable")}
                        </p>
                    )}

                    {lotData?.cleaning !== 0 && (
                        <p className="status mb-2">
                            <img src={wipe} className="icon" alt="wipe" />
                            {t("apartment.clean")}
                        </p>
                    )}

                    {lotData?.heating !== 0 && (
                        <p className="status mb-2">
                            <img src={heater} className="icon" alt="wipe" />
                            {t("apartment.heat")}
                        </p>
                    )}

                    {lotData?.building_service_charge !== 0 && (
                        <p className="status mb-2">
                            <img src={building} className="icon" alt="wipe" />
                            {t("apartment.serviceCharge")}
                        </p>
                    )}

                </ul>
            </div>

            {/* Détails de votre réservation */}
            <div className="booking-details">
                <h4>{t("bookingapart.detail")}</h4>
                <div class="date-container">
                    <p class="date-item">{t("bookingapart.arrivee")} <br/> <strong>{formatDate(arrivalDate)}</strong></p>
                    <div class="date-separator"></div>
                    <p class="date-item">{t("bookingapart.depart")} <br/> <strong>{formatDate(departureDate)}</strong></p>
                </div>

                <p>{t("bookingapart.duree")} : <strong>{calculateNights(arrivalDate, departureDate)} nuits</strong></p>
            </div>

            {/* Récapitulatif du moment */}
            <div className="summary">
                <h4>{t("bookingapart.recap")}</h4>
                <div className="total-amount">
                    <p>{t("bookingapart.montant")}</p>
                    <div>
                    {lotData?.promo && lotData.promo === 1 && (
                        <span className='price'><span className='price-loyer'>€ {lotData.tarif_promo} </span> </span>
                    )}
                    <span className="price"><span className='price-loyer'>€ {lotData?.loyer_hc + lotData?.charges} </span> </span>
            
                    <small>{t("bookingapart.taxe")}</small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsReservation