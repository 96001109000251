import React, { useState } from 'react'
import image from '../../assets/animation_500_lj4c3zmw 1.svg'
import { format } from 'date-fns';
import ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./BookingRoom.scss"
import sendImg from "../../assets/send 1.svg"
import upload from "../../assets/image-gallery.svg"
import payslip from "../../assets/g2115.svg"
import certificate from "../../assets/certificate.svg"
import groupId from "../../assets/Groupe 1178.svg"
import inVoice from "../../assets/invoice.svg"
import file from "../../assets/file.svg"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import BookingProcess from '../BookingProcess/BookingProcess';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendarIcon from '../../assets/calendar.svg';
import { Helmet } from 'react-helmet';
import { URL } from '../Variables'
import loading1 from '../../assets/loadwithoutbg.gif'
import { URL_Back } from '../Variables';
import { useTranslation } from 'react-i18next';
import MultiStepForm from '../Reservation/MultiStepForm/MultiStepForm';
const BookingRoom = () => {
  // ************************
  const { i18n, t } = useTranslation();
  const language = i18n.language;
  const [mainFile, setMainFile] = useState(null);
  const [subFiles, setSubFiles] = useState([null, null, null]);
  const [newFile, setNewFile] = useState(null);
  const [fileVisible, setFileVisible] = useState([true, true, true, true, true]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const { id } = useParams();
  const [lotData, setLotData] = useState({});
  const API_KEY = 'a2b18f9cfb72eb93f3ce6b1c30372b59';
  const [isLoading, setIsLoading] = useState(false);
  const API_URL = `${URL_Back}/lot/${id}`;
  const [email, setEmail] = useState('')
  const [formData, setFormData] = useState({
    firstName: '',
    surname: '',

  });
  const [phoneNumber, setPhoneNumber] = useState("")
  const [country, setCountry] = useState('fr');
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    surname: '',
    email: '',
    moveInDate: '',
    moveOutDate: '',
  });
  const [moveInDate, setMoveInDate] = useState(null);
  const [moveOutDate, setMoveOutDate] = useState(null);
  const CustomInputIn = ({ value, onClick, onChange, name }) => (
    <div className="input-datepicker" onClick={onClick}>
      <input
        type="text"
        name={name}
        className="form-control"
        value={value}
        placeholder=""
        required
        readOnly
        onChange={onChange}
      />
      <span className="calendar-icon">
        <img src={calendarIcon} alt="Calendar" />
      </span>
    </div>
  );

  const CustomInputOut = ({ value, onClick, onChange, name }) => (
    <div className="input-datepicker" onClick={onClick}>
      <input
        type="text"
        name={name}
        className="form-control"
        value={value}
        placeholder=""
        required
        readOnly
        onChange={onChange}
      />
      <span className="calendar-icon">
        <img src={calendarIcon} alt="Calendar" />
      </span>
    </div>
  );
  const [phoneNumberWithoutCode, setPhoneNumberWithoutCode] = useState('');
  const [code, setCode] = useState()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };
  const handleEmailChange = (event) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setEmailValid(validateEmail(emailValue));
    setFormErrors((prevErrors) => ({ ...prevErrors, email: '' }));
  }
  const validateEmail = (email) => {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    const headers = {
      'apiKey': `${API_KEY}`,
    };
    fetch(API_URL, { method: 'GET', mode: 'cors', headers })
      .then(response => response.json())
      .then(data => {
        setLotData(data.data.lot);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données :', error);
      });
  }, [API_URL, API_KEY])

  const handleCustomInputInChange = (date) => {
    setMoveInDate(date);
  };
  const handleCustomInputOutChange = (date) => {
    setMoveOutDate(date);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      const formDataToSend = new FormData();
      formDataToSend.append('lot_id', id);
      formDataToSend.append('first_name', formData.firstName);
      formDataToSend.append('last_name', formData.surname);
      formDataToSend.append('email', email);
      formDataToSend.append('start_date', format(moveInDate, 'yyyy-MM-dd'));
      formDataToSend.append('end_date', format(moveOutDate, 'yyyy-MM-dd'));
      if (phoneNumberWithoutCode) {
        formDataToSend.append('phone_number', phoneNumberWithoutCode);
        formDataToSend.append('phone_country_name', country);
      }
      if (mainFile) {
        formDataToSend.append('identity', mainFile);
      }
      if (subFiles[0]) {
        formDataToSend.append('school_certificate', subFiles[0]);
      }
      if (subFiles[1]) {
        formDataToSend.append('guarantor_id', subFiles[1]);
      }
      if (subFiles[2]) {
        formDataToSend.append('guarantor_tax_declaration', subFiles[2]);
      }
      if (newFile) {
        formDataToSend.append('professional_tax_declaration', newFile);
      }
      try {
        const response = await fetch(`${URL_Back}/rental_request/post`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'apiKey': API_KEY,
            'lang': `${i18n.language}`
          },
          body: formDataToSend,
        });

        if (response.ok) {
          setFormSubmitted(true);
          window.scrollTo(0, 0);
        } else {
          if (response.status === 422) {
            const errorData = await response.json();
            if (errorData) {
              toast.error(errorData.data.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 6000,
              });
            } else {
              toast.error('Validation Error, please check your input', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 6000,
              });
            }
          } else {
            console.error('Error making the request. Status:', response.status);
            toast.error('Error making the request. Please try again.', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 6000,
            });
          }
        }
      } catch (error) {
        console.error('Error making the request:', error.message);
        toast.error('Error making the request. Please try again.', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 6000,
        });
      }
      finally {
        setIsLoading(false)
      }
    } else {
      window.scrollTo(0, 0);
    }
  };
  const handleclick = (index) => {
    const updatedFileVisible = [...fileVisible];
    updatedFileVisible[index] = !updatedFileVisible[index];
    setFileVisible(updatedFileVisible);
  };
  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    const updatedFileVisible = [...fileVisible];
    if (index === 0) {
      setMainFile(file);
      updatedFileVisible[0] = true;
    } else if (index <= 3) {
      const newSubFiles = [...subFiles];
      newSubFiles[index - 1] = file;
      setSubFiles(newSubFiles);
      updatedFileVisible[index] = true;
    } else if (index === 4) {
      setNewFile(file);
      updatedFileVisible[4] = true;
    }
    setFileVisible(updatedFileVisible);
    e.target.value = '';
  };

  // ***************
  const validateForm = () => {
    const errors = {};
    let isValid = true;
    if (!formData.firstName.trim()) {
      errors.firstName = t("validation.firstName");
      isValid = false;
    }
    if (!formData.surname.trim()) {
      errors.surname = t("validation.lastName");
      isValid = false;
    }
    if (!email.trim()) {
      errors.email = t("validation.email");
      isValid = false;
    }
    if (!moveInDate) {
      errors.moveInDate = t("validation.moveIn");
      isValid = false;
    }
    if (!moveOutDate) {
      errors.moveOutDate = t("validation.moveOut");
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const handleMoveInDateChange = (date) => {
    if (date) {
      setMoveInDate(date);
      setFormErrors((prevErrors) => ({ ...prevErrors, moveInDate: '' }));
    }
  };
  const handleMoveOutDateChange = (date) => {
    if (date) {
      setMoveOutDate(date);
      setFormErrors((prevErrors) => ({ ...prevErrors, moveOutDate: '' }));
    }
  };
  const handlePhone = (value, data) => {
    setPhoneNumber(value);
    setCountry(data.countryCode);
    // Extraire le code de composition du numéro
    setCode(data.dialCode);
    // Vérifier si le numéro commence par le code de composition
    if (value.startsWith(`+${code}`)) {
      // Utiliser substring pour obtenir la partie après le code de composition
      const phoneNumberWithoutCode = value.substring(`+${code}`.length).trim();
      setPhoneNumberWithoutCode(phoneNumberWithoutCode);
    } else if (value.startsWith(code)) {
      // Utiliser substring pour obtenir la partie après le code de composition
      const phoneNumberWithoutCode = value.substring(code.length).trim();
      setPhoneNumberWithoutCode(phoneNumberWithoutCode);
    } else {
      // Le numéro ne commence ni par le code de composition ni par le code seul
      setPhoneNumberWithoutCode(value.trim());
    }
  };
  const moment = require('moment');
  function convertAvailabilityDateToDateObject(availabilityDate) {
    const availabilityMoment = moment(availabilityDate);
    if (!availabilityMoment.isValid()) {
      return null;
    }
    const dateObject = availabilityMoment.toDate();
    return dateObject;
  }
  const formattedDate = lotData && lotData.availability_date ? convertAvailabilityDateToDateObject(lotData.availability_date) : null;
  const minDate = new Date(formattedDate);
  minDate.setDate(minDate.getDate() + 1);

  console.log(lotData)
  return (
    <>
      <Helmet>
        <title>{t('breadcrumb.book')}</title>
        <meta
          name="description"
          content="Landes Location"
        />
        <link rel="canonical" href={`${URL}/booking-enquiry/${id}`} />
        <script type="application/ld+json">
          {`
 {
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Booking",
  "url": "${URL}/booking-enquiry/${id}",
  "description": "page apply for a room"
}

  
  `}
        </script>
      </Helmet>
      {lotData && lotData.title && lotData.building && lotData.building.title && lotData.building.city && (
        <Breadcrumbs
          path={`/`}
          customRoutes={[
            {
              path: `/${language}/search-cities?city=${lotData.building.city}`,
              label: `${lotData.building.city}`
            },
            {
              path: `/apartment/${lotData.id}`,
              label: `${lotData.building.title} - ${lotData.title}`
            }
          ]}

        />)}

      <ToastContainer />
      <div className="container">

      {lotData &&  (
  <MultiStepForm lotData={lotData} />
)}

      </div>

    </>
  )
}
export default BookingRoom